
<div class="signout_popup ">
  <div (click)="auditReport()" class="cursor px-10 py-10">
    <ion-text class="bodyM-txt " color="gray80">Appointment History</ion-text>
  </div>
</div>

<div class="signout_popup ">
  <div (click)="logotUser()" class="cursor px-10 py-10">
    <ion-text class="bodyM-txt " color="gray80">Logout</ion-text>
  </div>
</div>