import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class IonicToasterService {

  constructor(private toastController: ToastController) { }

  async showSuccess(message: string) {
    try {
      const toast = await this.toastController.create({
        message: message,
        duration: 5000,
        color: 'success',
        position: 'top',
        buttons: [
          {
            text: 'Close',
            role: 'cancel'
          }
        ]
      });
      toast.present();
    } catch (error) {
      console.error('Error creating toast:', error);
    }    
  }

  async showError(message: string) {
    try {
      const toast = await this.toastController.create({
        message: message,
        duration: 5000,
        color: 'danger',
        position: 'top',
        buttons: [
          {
            text: 'Close',
            role: 'cancel'
          }
        ]
      });
      toast.present();
    } catch (error) {
      console.error('Error creating toast:', error);
    }
    
  }

  handleHttpError(error: any) {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else if (error.status) {
      errorMessage = `Backend returned code ${error.status}, body was: ${error.error}`;
    }
    this.showError(errorMessage);
  }

  handleNonHttpError(errorMessage: string) {
    this.showError(errorMessage);
  }

}
