import { Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SelfSchedulingCropperComponent } from '../components/self-scheduling-cropper/self-scheduling-cropper.component';
import { firstValueFrom, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class SelfSchedulingModalService {

  public croppedImage: string | null = null;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  constructor(private dialogService: DialogService, private router: Router, private deviceService: DeviceDetectorService) {
    this.epicFunction();
  }

  async openDynamicModal(data: any): Promise<any> {

    if (this.isMobile) {
      // Navigate to the ImageCropperComponent route for mobile devices
      await this.router.navigate(['/app/cropper'], { state: { data } });

      // Return a promise that resolves when the navigation event is complete
      return new Promise((resolve) => {
        const subscription = this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const navigation = this.router.getCurrentNavigation();
            const state = navigation?.extras.state;
            if (state) {
              this.handleDialogResult(state).then((result: any) => {
                resolve(result);
                subscription.unsubscribe(); // Clean up subscription
              });
            }
          }
        });
      });

    } else {
      // Open modal for desktop and tablet devices
      const dialogRef: DialogRef = this.dialogService.open({
        content: SelfSchedulingCropperComponent,
      });
      const userInfo = dialogRef.content.instance as SelfSchedulingCropperComponent;
      userInfo.data = data;
    
      // Handle the result of the dialog
      try {
        const result = await firstValueFrom(dialogRef.result);
        return result;
      } catch (error) {
        console.log('Dialog was closed without action.');
        return null;
      }
    }
  }

  private async handleDialogResult(data: any): Promise<any> {
    return new Promise((resolve) => {
      if (data.confirmed) {
        resolve(data); // Resolve the promise with the data
      } else {
        resolve(null); // Resolve the promise with null if canceled
      }
    });
  }  

  //Device Dectector
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  // Observable source
  private triggerHideModalsSource = new Subject<void>();
  private openSigninPopupSource = new Subject<void>();

  // Observable stream
  triggerHideModals$ = this.triggerHideModalsSource.asObservable();
  openSigninPopup$ = this.openSigninPopupSource.asObservable();

  // Service method to trigger the event
  triggerHideModals() {
    this.triggerHideModalsSource.next();
  }

  openSigninPopup() {
    this.triggerHideModals();
    setTimeout(() => {
      this.openSigninPopupSource.next();
    }, 0);
  }

}
