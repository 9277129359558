
export const environment = {
  production: false,

  currentEnvironment: 'Development',
  
  recaptchaSiteKey: '6Lery-UpAAAAALm41r_hVCHakR5CvEhOyJ0v4OMK',
  myqone_url: "https://qore-dev.myqone.com",
  patient_url: "https://qore-dev.myqone.com/patient",
  auth_url: 'https://qore-dev.myqone.com/auth',
  qappoints_url: 'https://qore-dev.myqone.com/qappointments',
  communication_url: 'https://qore-dev.myqone.com/communication/',
  otc_url: 'https://devservices.qpathways.com/otc/',
  dms_url: 'https://qdrive-dev.myqone.com/dms',
  secretKey: '=a@S;nh9WwC#)Ph+D>;8F8e&"`P|-pJ.',
  pebackend_url: 'https://qpatientengsrv-dev.myqone.com/pe2backend/',
  auditUrl: "https://qore-dev.myqone.com/audit/v1/",
  qintake_url:"https://qpatient-dev.myqone.com/qintake",

  firebaseConfig: {
    // apiKey: 'AIzaSyAf6l7qoiXHWg1n4V0gqaWC-jXm4yvVkwA',
    apiKey: 'AIzaSyCKHy-uhhXbITqXSteKv7lAKdAVbFwpPMY',
    authDomain: 'qpathways-dev.firebaseapp.com',
    databaseURL: 'https://qpathways-dev.firebaseio.com',
    projectId: 'qpathways-dev',
    storageBucket: 'qpathways-dev.appspot.com',
    messagingSenderId: '413178660353',
    appId: '1:413178660353:web:6f8e21f6ecd9b665d4a200',
    measurementId: 'G-K21LBEKQLR',
    vapidKey:
      'BPnM0ilOA6PD4yKM7FtYFys9c3zLRw-OKyxQOmaC6aOtsShKGXKhSPqksihzlROOA8ByJ_t5DEZyPPSRIPmjC_E',
  },
};


// export const environment = {
//   production: true,
//   currentEnvironment: 'qa',
//   recaptchaSiteKey: '6Lery-UpAAAAALm41r_hVCHakR5CvEhOyJ0v4OMK',
//   myqone_url: "https://qore-qa.myqone.com",
//   patient_url: "https://qore-qa.myqone.com/patient",
//   auth_url: 'https://qore-qa.myqone.com/auth',
//   qappoints_url: 'https://qore-qa.myqone.com/qappointments',
//   communication_url: 'https://qore-qa.myqone.com/communication/',
//   otc_url: 'https://qaservices.qpathways.com/otc/',
//   dms_url: "https://qdrive-qa.myqone.com/dms",
//   secretKey: '=a@S;nh9WwC#)Ph+D>;8F8e&"`P|-pJ.',
//   pebackend_url: 'https://qpatientengsrv-qa.myqone.com/pe2backend/',
//   auditUrl: 'https://qore-qa.myqone.com/audit/v1/',
//   clearGageUrl: "https://demo.acceleratepayments.com/api/v2/",
//   qintake_url:"https://qpatient-qa.myqone.com/qintake",
  
//   firebaseConfig: {
//       // apiKey: 'AIzaSyAndriP5Pz44_GDTIafEHyEoKSVz65d1Js',
//       apiKey: 'AIzaSyByhp1cbQRIh7-ZC4QVRY4Bg7qf9cJ80No',
//       authDomain: 'qpathways-qa.firebaseapp.com',
//       databaseURL: 'https://qpathways-qa.firebaseio.com',
//       projectId: 'qpathways-qa',
//       storageBucket: 'qpathways-qa.appspot.com',
//       messagingSenderId: '729113941120',
//       appId: '1:729113941120:web:d69586e2eb0eb5cf5a4265',
//       measurementId: 'G-9CNN43SV49',
//       vapidkey:
//           'BFWSY3UA2Hv86k-lCasOZ00WyJ9dOSFm3YB8xuBjbNwtdMzZUj-86qvWI_xuRet3pNZnat_0J2WzUIQ8CmXdiis',
//   },
      
// };