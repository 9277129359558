import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { catchError, EMPTY, Subscription } from 'rxjs';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { getAuth, signInAnonymously } from "firebase/auth";
import { PatientAppointmentLoginService } from '../../services/patient-appointment-login.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuditLogService } from '../../services/audit-log.service';

export function nonEmptyObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = control.value && Object.keys(control.value).length > 0;
    return isValid ? null : { 'nonEmptyObject': true };
  };
}

// Validator to allow either email or username
export function emailOrUsernameValidator(): ValidatorFn {
  const emailPattern = /^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
  const usernamePattern = /^[a-zA-Z0-9_]+$/; // Username: letters, numbers, underscores only

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // If field is empty, return null (handled by required validator separately)
    if (!value) return null;

    // Check if value matches either the email or username pattern
    const isEmail = emailPattern.test(value);
    const isUsername = usernamePattern.test(value);

    // If neither pattern matches, return an error
    return isEmail || isUsername ? null : { invalidUsernameOrEmail: true };
  };
}
@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.scss'],
})
export class PatientAppointmentComponent implements OnInit, AfterViewChecked, OnDestroy {
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent;

  selectedAppointmentType: any | null = null;
  allAppointmentTypes: any[] = [];
  locations: any = [];
  providers: any = [];
  loginForm!: FormGroup;
  // newPatientLoginForm!: FormGroup;
  subscription: Subscription = new Subscription();
  ifError: boolean = false;
  errorMessageForNew: boolean = false;
  errorMessage: string = '';
  errorMessageForNewPatient: string = '';
  noPracticeListError1: string = '';
  noPracticeListError2: string = '';
  showError: boolean = false;
  selectedTab: string = "";
  selectedLocation: any;
  selectedProvider: any;
  selectedTime: any;
  selectedReason: string = '';
  tenantId: any;
  patientList: any;
  selectedPatient: any;
  selectedPatientForExisting: any;
  isButtonDisabled: boolean = true;
  ifErrorMessage: boolean = false;

  welcomeTextMsg: any;
  sanitizedWelcomeText: SafeHtml;
  tenantName: any;
  helpLineNumber: any;

  isHelpine: boolean = false;
  // isConfirmationText: boolean = false;
  isWelcomeText: boolean = false;

  isMobileHeader: boolean = false;
  isforgetPasswords: boolean = false;

  // loading: any; // Global Loader, Not in use

  constructor(private deviceService: DeviceDetectorService, private router: Router, private appointmentService: PatientAppointmentService, private loginService: PatientAppointmentLoginService, private commonService: CommonService, private route: ActivatedRoute,
    private fb: FormBuilder, public toasterService: ToasterService, private sanitizer: DomSanitizer, private auditLogService: AuditLogService, private cdr: ChangeDetectorRef) {
    this.preventForwardButton();
    this.loginForm = this.fb.group({ // Existing patient
      username: ['', [Validators.required, emailOrUsernameValidator(), Validators.maxLength(50)]],
      password: ['', Validators.required],
      appointmentType: [{}, [Validators.required, nonEmptyObjectValidator()]]
    });
    // this.newPatientLoginForm = this.fb.group({ // New pateint
    //   appointmentType: [{}, [Validators.required, nonEmptyObjectValidator()]],
    //   location: [{}],
    //   provider: [{}],
    //   time: [""]
    // });
    this.tenantId = this.route.snapshot.paramMap.get('tenant');
    this.commonService.removeAllStorage();
    this.commonService.removeAllLocalStoarage();
  }

  async ngOnInit() {
    await this.initializeComponent();
    this.route.params.subscribe(async (params) => {
      this.commonService.removeAllStorage();
      this.commonService.removeAllLocalStoarage();
      this.resetFilters();
      await this.initializeComponent();
    });
  }

  resetFilters() {
    // this.newPatientLoginForm.reset();
    this.loginForm.reset();
  }

  async initializeComponent() {
    this.epicFunction();
    if (this.isMobile) {
      this.isMobileHeader = true;
    } else {
      this.isMobileHeader = false;
    }
    await this.checkUserSession();
    this.getTenantConfigurations();
  }

  // Push the current state to history to block forward navigation
  private preventForwardButton(): void {
    // Push a dummy state to prevent forward navigation
    history.pushState(null, '', window.location.href);

    window.onpopstate = (event: PopStateEvent) => {
      // Prevent forward navigation by re-pushing the current state
      history.pushState(null, '', window.location.href);
    };
  }

  ngAfterViewChecked() {
    if (this.passwordInput && this.passwordInput.input) {
      this.passwordInput.input.nativeElement.type = 'password';
    }
  }

  ngOnDestroy() {
    // Remove the popstate listener when the component is destroyed
    window.onpopstate = null;
  }

  async checkUserSession() {
    const existingUser = this.commonService.getSessionStorage('PI');
    if (existingUser) {
      // console.log('User session exists, refreshing token...');
      try {
        await this.refreshUserToken(); // Refresh the token if user already exists
      } catch (error) {
        console.error('Error refreshing token:', error);
        await this.setNewUser(); // If refreshing fails, create a new anonymous user session
      }
    } else {
      // console.log('No user session found, creating new anonymous user...');
      await this.setNewUser(); // Create a new user session if none exists
    }
  }

  async refreshUserToken(): Promise<void> {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        const newToken = await user.getIdToken(true); // Force token refresh
        // console.log('Token refreshed:', newToken);
        const obj = {
          userid: user.uid,
          token: newToken
        };
        this.commonService.setSessionStorage('PI', JSON.stringify(obj));
      } catch (error) {
        console.error('Error refreshing token:', error);
        // this.loading.dismiss();
        throw error; // Ensure caller handles the error
      }
    } else {
      console.warn('No user session available, creating new user...');
      await this.setNewUser(); // Handle missing session by signing in anonymously
    }
  }

  //Device Dectector
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  // showDashboard: boolean = false;

  async getTenantConfigurations(): Promise<void> {
    try {
      // this.loading = await this.appointmentService.showBookingLoader();
      return new Promise((resolve, reject) => {
        this.appointmentService.getTenantConfigurations(this.tenantId).pipe(
          catchError((error) => {
            console.log('Error in getTenantConfigurations:', error);
            this.isHelpine = false;
            // this.isWelcomeText = false;
            // this.isConfirmationText = false;
            // this.loading.dismiss();
            resolve();  // Resolve the promise even on error to proceed with further initialization
            this.router.navigate(['/app/setting-not-found']);
            return EMPTY;
          })
        ).subscribe((res: any) => {
          if (res?.status === true) {
            if (Array.isArray(res?.data)) {
              const newPatientSetting = res.data.find((setting: { settingname: string; }) => setting?.settingname === "newPatient");
              const returningPatientSetting = res.data.find((setting: { settingname: string; }) => setting?.settingname === "returningPatient");
              const qsuiteSetting = res.data.find((setting: { settingname: string; }) => setting?.settingname === "QSuiteTemplate");

              if (!newPatientSetting && !returningPatientSetting) {
                // this.loading.dismiss();
                resolve();
                this.router.navigate(['/app/setting-not-found']);
                return;
              }

              if (!qsuiteSetting) {
                // this.loading.dismiss();
                resolve();
                this.router.navigate(['/app/setting-not-found']);
                return;
              }

              // Process configuration settings
              const promises = res.data.map((n: any) => this.processConfigurationSetting(n));
              Promise.all(promises).then(() => {
                // After all settings have been processed, check patient types
                if (newPatientSetting) {
                  this.getPatientTypeForTenant(newPatientSetting.settingname, newPatientSetting.settingvalue);
                }
                if (returningPatientSetting) {
                  this.getPatientTypeForTenant(returningPatientSetting.settingname, returningPatientSetting.settingvalue);
                }

                // Evaluate patient types after processing
                this.evaluatePatientTypes();
                resolve();
                // this.loading.dismiss(); // Close the loader after successfully resolving every promise
                this.getAllData();  // Call getAllData when QSuiteTemplate & patientSetting exists
              }).catch((error) => {
                console.error('Error in Promise.all:', error);
                // this.loading.dismiss();
                reject(error);
              });
            } else {
              // this.loading.dismiss();
              console.error('Unexpected Data, Response is not an array');
            }
          } else {
            this.isHelpine = false;
            // this.isWelcomeText = false;
            // this.isConfirmationText = false;
            // this.loading.dismiss();
            resolve();
            this.toasterService.showError('Unexpected Error, Please contact your admin');
            this.router.navigate(['/app']);
          }
        });
      });
    } catch {
      // this.loading.dismiss();
      this.toasterService.showError('unexpected error');
      this.router.navigate(['/app/setting-not-found']);
    }
  }

  newPatient: boolean = false;
  returningPatient: boolean = false;

  private processConfigurationSetting(n: any): Promise<void> {
    try {
      if (!n) return Promise.resolve();

      return new Promise((resolve, reject) => {
        switch (n?.settingname) {
          case "newPatient":
            this.getPatientTypeForTenant(n.settingname, n.settingvalue);
            break;

          case "returningPatient":
            this.getPatientTypeForTenant(n.settingname, n.settingvalue);
            break;

          case "helpLineNumber":
            this.isHelpine = true;
            this.commonService.setSessionStorage('helpLineNumber', JSON.stringify(n.settingvalue));
            break;

          // case "welcomeText":
          //   this.isWelcomeText = true;
          //   this.commonService.setSessionStorage('welcomeText', JSON.stringify(n.settingvalue));
          //   const storedText = this.commonService.getSessionStorage('welcomeText');
          //   if (storedText) {
          //     this.sanitizedWelcomeText = this.sanitizer.bypassSecurityTrustHtml(storedText);
          //   }
          //   break;

          case "QSuiteTemplate":
            this.checkTemplateStatus(n.settingname, n.settingvalue).then(resolve).catch(reject);
            return;

          default:
            break;
        }
        resolve(); // Called once at the end for all non-QSuiteTemplate cases.
      });
    } catch {
      // this.loading.dismiss();
      this.router.navigate(['/app/setting-not-found']);
      return this.toasterService.showError('unexpected error');
    }
  }

  private hasNewPatient = false;
  private hasReturningPatient = false;
  showLoader: boolean = true;

  private getPatientTypeForTenant(settingname: string, settingvalue: string) {
    if (settingname === 'newPatient') {
      // Only set hasNewPatient to true if the value is exactly "true"
      if (settingvalue.toLowerCase() === "true") {
        this.newPatient = true;
        this.hasNewPatient = true;
      } else {
        this.newPatient = false;
        this.hasNewPatient = false;
      }
    }

    if (settingname === 'returningPatient') {
      // Only set hasReturningPatient to true if the value is exactly "true"
      if (settingvalue.toLowerCase() === "true") {
        this.returningPatient = true;
        this.hasReturningPatient = true;
      } else {
        this.returningPatient = false;
        this.hasReturningPatient = false;
      }
    }

    // Logic for setting the selectedTab based on the flags
    if (this.hasNewPatient && this.hasReturningPatient) {
      this.selectedTab = "New Patient"; // Both are true
    } else if (this.hasNewPatient) {
      this.selectedTab = "New Patient"; // Only newPatient is true
    } else if (this.hasReturningPatient) {
      this.selectedTab = "Returning Patient"; // Only returningPatient is true
    } else {
      this.selectedTab = ""; // Reset or handle accordingly if none are true;
    }
    this.showLoader = false;
  }

  private evaluatePatientTypes() {
    // Check if both newPatient and returningPatient are false
    if (!this.newPatient && !this.returningPatient) {
      // this.loading.dismiss();
      this.router.navigate(['/app/setting-not-found']);
    }
  }

  private checkTemplateStatus(settingname: string, templateName: string): Promise<void> {
    try {
      return new Promise((resolve, reject) => {
        this.appointmentService.getTemplateStatus(this.tenantId, templateName).pipe(
          catchError((error) => {
            // this.loading.dismiss();
            resolve();  // Resolve the promise even on error to proceed with further initialization
            return EMPTY;
          })
        ).subscribe((response: any) => {
          if (response && response?.data?.status === true) {
            this.commonService.setSessionStorage(settingname, JSON.stringify(templateName));
          } else {
            this.router.navigate(['/app/setting-not-found']);
          }
          resolve();
        });
      });
    } catch {
      // this.loading.dismiss();
      this.router.navigate(['/app/setting-not-found']);
      return this.toasterService.showError('unexpected error');
    }
  }

  getAllData() {
    this.getTenantName();
    this.getAppointmentTypeList();
    this.getlocations();
    this.getProviders();
  }

  async getTenantName(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.appointmentService.getTenantNameWithId(this.tenantId).pipe(
        catchError((error) => {
          console.error('Error:', error);
          resolve(); // Resolve to ensure the function completes even if there's an error
          return EMPTY;
        })
      ).subscribe((res: any) => {
        if (res && res?.tenantname) {
          this.tenantName = res.tenantname;
          this.commonService.setSessionStorage('tenantName', JSON.stringify(this.tenantName));
        }
        resolve(); // Resolve when the subscription completes
      });
    });
  }

  isLoading: boolean = true;
  getAppointmentTypeList() {
    this.appointmentService.getAppointmentType(this.tenantId).pipe(catchError((error) => {
      console.log(error);
      this.isLoading = false;
      return EMPTY;
    })).subscribe((res: any) => {
      if (res) {
        this.allAppointmentTypes = res.map((aapType: any) => ({
          appointmenttypedescription: aapType.appointmenttypedescription,
          appointmentType: aapType
        }));
        this.allAppointmentTypes.sort((a: any, b: any) => a.appointmenttypedescription.localeCompare(b.appointmenttypedescription));
        this.isLoading = false;
      }
    });
  }

  getlocations() {
    this.appointmentService.getlocationList(this.tenantId).pipe(catchError((error) => {
      console.log(error);
      return EMPTY;
    })).subscribe((location: any) => {
      if (location) {
        this.locations = location.map((loc: any) => ({
          locationdescription: loc.locationdescription,
          location: loc
        }));
        this.locations.sort((a: any, b: any) => a.locationdescription.localeCompare(b.locationdescription));
      }
    });
  }

  getProviders() {
    this.appointmentService.getProviderList(this.tenantId).pipe(catchError((error) => {
      console.log(error);
      return EMPTY;
    })).subscribe((provider: any) => {
      if (provider) {
        this.providers = provider.map((provider: any) => provider.providerfirstname + ' ' + provider.providerlastname);
        this.providers = provider.map((provider: any) => ({
          providerName: provider.providerfirstname + ' ' + provider.providerlastname,
          provider: provider
        }));
        this.providers.sort((a: any, b: any) => a.providerName.localeCompare(b.providerName));
      }
    });
  }

  getPortalLoginUserDetails() {
    if (this.loginForm.get('appointmentType').invalid) {
      this.ifError = true;
      this.errorMessage = 'Please select a appointment type';
      return;
    }
    if (this.loginForm.valid) {
      const username = this.loginForm.get('username')?.value;
      const password = this.loginForm.get('password')?.value;
      const encodedPassword = btoa(password);
      let appointmentType = this.loginForm.value.appointmentType;

      this.loginService.getPortalAccessUserDetails(username, encodedPassword, this.tenantId).pipe(catchError((error) => {
        this.ifError = true;
        if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
          this.errorMessage = 'The email or password you entered is incorrect. Please check and try again.';
        } else if (error.code === 'auth/too-many-requests') {
          this.errorMessage = 'Too many unsuccessful login attempts. For security reasons your account is locked temporarily. Please try again later.';
        } else {
          this.errorMessage = 'An error occurred';
        }
        return EMPTY;
      })).subscribe((resData: any) => {
        if (resData?.status === 200 && resData?.data) {
          this.commonService.setSessionStorage('appointmentType', JSON.stringify(appointmentType));
          let patientid = resData.data[0].nPatientID;
          this.commonService.setSessionStorage('patientId', JSON.stringify(patientid));
          let emrmappingid = resData.data[1].emrmappingid;
          this.loginService.getTokenforPortalUser(emrmappingid).pipe(catchError((error) => {
            return EMPTY;
          })).subscribe((res: any) => {
            if (res) {
              let generatedToken = res?.token;
              this.commonService.setSessionStorage('existingtoken', JSON.stringify(generatedToken));
              let obj = {
                firstname: res?.firstname,
                lastname: res?.lastname,
                userid: res?.userid,
                token: res?.token
              }
              this.commonService.setSessionStorage('UID', JSON.stringify(res?.uid));
              this.commonService.setSessionStorage('fn', JSON.stringify(res?.firstname + " " + res?.lastname));
              this.commonService.setSessionStorage('usid', JSON.stringify(res?.userid));
              this.commonService.setSessionStorage('patientEmail', JSON.stringify(username));
              this.commonService.setSessionStorage('PI', JSON.stringify(obj));
              this.commonService.setSessionStorage('patientType', JSON.stringify('Returning Patient'));
              this.commonService.setSessionStorage('appointmentType', JSON.stringify(this.selectedAppointmentType));
              this.saveAuditLogsToPg('patientLogin');
              this.router.navigate(['/app/patient-appointment-time']);
            } else {
              console.error('Error: No response received');
            }
          });
        } else {
          this.ifError = true;
          this.errorMessage = 'The username or password entered is invalid';
        }
      });
    } else {
      this.ifError = true;
      this.errorMessage = 'Please enter a valid username or a valid email address.';
    }
  }

  saveAuditLogsToPg(value: string) {
    let tenantId = this.commonService.getSessionStorage('tenant');
    let patientId = this.commonService.getSessionStorage('patientId');
    let name = this.commonService.getSessionStorage('fn');

    const parts = patientId.split('-');
    const trimmedValue = parts[0];

    const auditData = this.auditLogService.generateAuditLogForPg(value, name, tenantId, trimmedValue);
    if (auditData) {
      this.appointmentService.saveAuditLogsToPg(auditData)
        .pipe(
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        ).subscribe(); // No action needed for the response
    }
  }

  public resetErrorMsg(): void {
    this.ifError = false;
  }

  onLocationChange(selectedLocation: any) {
    if (selectedLocation && selectedLocation?.location && selectedLocation?.location?.externalref &&
      selectedLocation?.location?.externalref?.emrid) {
      this.selectedLocation = selectedLocation.location.externalref.emrid;
    } else {
      this.selectedLocation = '';
      console.warn('No valid emrid found in the selected location:', selectedLocation);
    }
  }

  onProviderChange(event: any) {
    this.selectedProvider = event?.provider?.providerid;
    if (this.selectedProvider) {
      const parts = this.selectedProvider.split('-');
      const trimmedValue = parts[0];
      this.selectedProvider = trimmedValue;
    } else {
      console.warn('no provider id selected');
    }
  }

  // Not in use
  // onReasonChangeForNewPatient(event: any) {
  //   this.selectedPatient = event;
  //   if (this.selectedPatient) {
  //     this.errorMessageForNewPatient = '';
  //     this.isButtonDisabled = false;
  //     this.errorMessageForNew = false;
  //   } else {
  //     this.errorMessageForNew = true;
  //     this.errorMessageForNewPatient = 'Please select a reason for visit';
  //     this.isButtonDisabled = true;
  //   }
  // }

  //Appointment type
  onAppointmentTypeSelect(event: any) {
    this.selectedAppointmentType = event?.appointmentType?.appointmenttypedescription;
    if (this.selectedAppointmentType) {
      this.errorMessageForNewPatient = '';
      this.isButtonDisabled = false;
      this.errorMessageForNew = false;
    } else {
      this.errorMessageForNew = true;
      this.errorMessageForNewPatient = 'Please select an appointment type';
      this.isButtonDisabled = true;
    }
  }

  onAppointmentTypeSelectforExisting(event: any) {
    this.selectedAppointmentType = event?.appointmentType?.appointmenttypedescription;
    if (this.selectedAppointmentType) {
      this.errorMessage = '';
      this.isButtonDisabled = false;
      this.ifError = false;
      this.ifErrorMessage = false;
    } else {
      this.ifError = true;
      this.ifErrorMessage = true;
      this.errorMessage = 'Please select an appointment type';
      this.isButtonDisabled = true;
    }
  }

  onTimeButtonClick(event: string) {
    this.selectedTime = event;
  }

  onReasonSelected(event: string) {
    this.selectedReason = event;
  }

  public onSelect(e: SelectEvent): void {
    this.selectedTab = e.title;
    if (this.selectedTab === 'New Patient') {
      this.isButtonDisabled = !this.selectedAppointmentType.trim();
      this.loginForm.reset();
      this.ifError = false;
      this.ifErrorMessage = false;
      this.isforgetPasswords = false;
    } else if (this.selectedTab === 'Returning Patient') {
      this.isButtonDisabled = !this.loginForm.get('appointmentType').valid;
      this.selectedAppointmentType = '';
      this.errorMessageForNew = false;
      this.isforgetPasswords = true;
    }
  }

  async findAppointmentSlots() {
    if (this.selectedTab === 'New Patient') {
      await this.setNewUser();
      if (this.selectedAppointmentType) {
        this.commonService.setSessionStorage('appointmentType', JSON.stringify(this.selectedAppointmentType));
      }
      if (this.selectedLocation) {
        this.commonService.setSessionStorage('locationId', JSON.stringify(this.selectedLocation));
      }
      if (this.selectedProvider) {
        this.commonService.setSessionStorage('providerId', JSON.stringify(this.selectedProvider));
      }
      if (this.selectedTime) {
        this.commonService.setSessionStorage('selectedTime', JSON.stringify(this.selectedTime));
      }
      this.router.navigate(['/app/patient-appointment-time']);
    } else {
      this.getPortalLoginUserDetails();
    }
  }

  async setNewUser() {
    const auth = getAuth();
    await signInAnonymously(auth).then((userCredential) => {
      let user = userCredential.user;
      const accessToken = user["accessToken"];
      let obj = {
        userid: user.uid,
        token: accessToken
      };
      this.commonService.setSessionStorage('tenant', JSON.stringify(this.tenantId));
      this.commonService.setSessionStorage('patientType', JSON.stringify(this.selectedTab));
      this.commonService.setSessionStorage('UID', JSON.stringify(obj.userid));
      this.commonService.setSessionStorage('PI', JSON.stringify(obj));
    }).catch((error) => {
      // this.loading.dismiss();
      this.toasterService.showError('Error signing in anonymously');
    });
  }


  //Forgot password
  isForgotPasswordPopup: boolean = false;

  openForgotPassword(): void {
    this.isForgotPasswordPopup = true;
  }

  closeForgotPassword() {
    this.isForgotPasswordPopup = false;
  }
}
