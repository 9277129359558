import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { LoadingController, Platform } from '@ionic/angular';
import { ToasterService } from './toaster.service';
import { environment } from "../../environments/environment";
import { ModalService } from './modal.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photos: UserPhoto[] = [];
  public cards: InsuranceCards[] = [];
  private PHOTO_STORAGE: string = 'photos';
  private IMAGE_STORAGE: string = 'cards';
  private platform: Platform;

  constructor(platform: Platform, private http: HttpClient,
    private loadingController: LoadingController, private toasterService: ToasterService, private modalService: ModalService) {
    this.platform = platform;
    this.loadSaved();
  }

  public async addNewToGallery(type: string) {
    try {
      // Check for camera permissions before capturing a photo
      await this.requestPermissions();

      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });

      // Check if the format is correct
      const allowedFormats = ['jpeg', 'jpg', 'png'];
      const format = capturedPhoto.format.toLowerCase();
      if (!allowedFormats.includes(format)) {
        this.toasterService.showError('Unsupported file format. Only JPEG and PNG or other image files type are allowed.');
        return;
      }

      // Read the file data to determine its size
      const response = await fetch(capturedPhoto.webPath!);
      if (!response.ok) {
        this.toasterService.showError('Failed to fetch file data.');
        return;
      }
      const blob = await response.blob();
      const fileSizeBytes = blob.size;

      // Check if the file size exceeds 20MB
      const fileSizeLimitMB = 20;
      const fileSizeLimitBytes = fileSizeLimitMB * 1024 * 1024; // Convert MB to bytes
      if (fileSizeBytes > fileSizeLimitBytes) {
        this.toasterService.showError('File size exceeds the limit of 15MB.');
        return;
      }

      // Resize the image
      const resizedBlob = await this.resizeImage(blob);

      // Pass the resized image to the modal
      const result = await this.modalService.openDynamicModal(URL.createObjectURL(resizedBlob));
      const croppedImage = result?.croppedImage || capturedPhoto.webPath!; // Use capturedPhoto.webPath if croppedImage is not available

      // Wait for savedImageFile to return file-path from savePicture function
      const savedImageFile = await this.savePicture(croppedImage);

      // Create an object and pass the value returned from savedImageFile to get filepath
      let newImageFile = {
        filepath: savedImageFile.filepath,
        webViewpath: savedImageFile.webViewpath,
        type: type
      };

      // If the user confirms the image from the modal, add it to the photos array
      if (result && result.confirmed) {
        this.photos.unshift(newImageFile);
        Preferences.set({
          key: this.PHOTO_STORAGE,
          value: JSON.stringify(this.photos),
        });
      } else {
        return;
      }
    } catch (error) {
      return console.log(error);
    }
  }
  
  private async resizeImage(blob: Blob): Promise<Blob> {
    const MAX_WIDTH = 700;
    const MAX_HEIGHT = 500;
  
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
  
        let newWidth = width;
        let newHeight = height;
  
        // Resize the image if it exceeds the maximum width or height
        if (width > MAX_WIDTH) {
          newWidth = MAX_WIDTH;
          newHeight = (height * MAX_WIDTH) / width;
        }
        if (height > MAX_HEIGHT) {
          newHeight = MAX_HEIGHT;
          newWidth = (width * MAX_HEIGHT) / height;
        }
  
        // Create a canvas element to draw the resized image
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
        // Convert the canvas to a Blob
        canvas.toBlob((resizedBlob) => {
          resolve(resizedBlob!);
        }, 'image/jpeg', 0.8); // Adjust the quality as needed
      };
    });
  }

  private async requestPermissions() {
    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.requestPermissions();
      if (permissions.camera == 'denied' || permissions.photos == 'denied') {
        // Show an error message to the user
        this.showErrorDialog();
        throw new Error('Camera permissions denied');
      }
    }
  }

  private showErrorDialog() {
    this.toasterService.showError('Camera permissions denied. Please allow camera access to use this feature.');
  }

  private async savePicture(croppedImage: string) {
    let photoData: string | Blob;
    if (this.platform.is('hybrid')) {
      photoData = croppedImage;
    } else {
      const base64Data = await this.readAsBase64(croppedImage);
      photoData = base64Data;
    }

    const fileName = Date.now() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: photoData,
      directory: Directory.Data
    });

    if (this.platform.is('hybrid')) {
      return {
        filepath: savedFile.uri,
        webViewpath: Capacitor.convertFileSrc(savedFile.uri),
      };
    } else {
      return {
        filepath: fileName,
        webViewpath: `data:image/jpeg;base64,${photoData}`
      };
    }
  }

  private async readAsBase64(photo: any) {
    if (this.platform.is('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path!
      });
      return file.data;
    } else {
      const response = await fetch(photo);
      const blob = await response.blob();
      return await this.convertBlobToBase64(blob) as string;
    }
  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  public async loadSaved() {
    const { value } = await Preferences.get({ key: this.PHOTO_STORAGE });
    this.photos = (value ? JSON.parse(value) : []) as UserPhoto[];
    if (!this.platform.is('hybrid')) {
      for (let photo of this.photos) {
        const readFile = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Data,
        });
        photo.webViewpath = `data:image/jpeg;base64,${readFile.data}`;
      }
    }
  }

  public async loadSavedInsurance() {
    const { value } = await Preferences.get({ key: this.IMAGE_STORAGE });
    this.cards = (value ? JSON.parse(value) : []) as InsuranceCards[];
    if (!this.platform.is('hybrid')) {
      for (let photo of this.cards) {
        for(let card of photo.cards) {
          const readFile = await Filesystem.readFile({
            path: card.filepath,
            directory: Directory.Data,
          });
          card.webViewpath = `data:image/jpeg;base64,${readFile.data}`;
        }
      }
    }
  }

  public async deletePicture(type: any, filePath: any) {
    this.photos = this.photos.filter(photo => photo.type !== type);
    Preferences.set({
      key: this.PHOTO_STORAGE,
      value: JSON.stringify(this.photos)
    });
  }

  async deleteCard(cardId: number) {
    this.cards = this.cards.filter(card => card.id !== cardId);

    await Preferences.set({
      key: this.IMAGE_STORAGE,
      value: JSON.stringify(this.cards)
    });
  }

  public async clearImagesIfCanceled() {
    this.photos = []; // Clearing the array
    Preferences.set({
      key: this.PHOTO_STORAGE,
      value: JSON.stringify(this.photos)
    });
  }

  saveCardDetails(frontImage: File, patientid: any, tenantid: any, cardtype: any, backImage: File) {
    let url = (environment.patient_url + '/patientcards/save');
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('frontimage', frontImage);
    formData.append('patientid', patientid);
    formData.append('tenantid', tenantid);
    formData.append('cardtype', cardtype);
    formData.append('backimage', backImage);
    return this.http.post<any>(url, formData, { headers });
  }

  async pleaseWait() {
    const loading = await this.loadingController.create({
      message: 'Loading, Please Wait...',
      spinner: 'circular',
      duration: 0
    });
    await loading.present();
    return loading; // Return the loading instance
  }

  async showLoader() {
    const loading = await this.loadingController.create({
      message: 'Saving Card Details...',
      spinner: 'circular',
      duration: 0
    });
    await loading.present();
    return loading; // Return the loading instance
  }

  async showCardLoader() {
    const loading = await this.loadingController.create({
      message: 'Loading Card Details...',
      spinner: 'circular',
      duration: 0
    });
    await loading.present();
    return loading;
  }

  async showdeleteLoader() {
    const loading = await this.loadingController.create({
      message: 'Deleting Cards...',
      spinner: 'circular',
      duration: 0
    });
    await loading.present();
    return loading;
  }

  getPatientCardList(patientId: any, tenantid: any, cardtype: any) {
    return this.http.get<any>(environment.patient_url + `/patientcards/getpatientcards/${patientId}/${tenantid}/${cardtype}`);
  }

  getPatientCardByte(patientcardsid: any, patientid: any, tenantid: any, cardtype: any) {
    return this.http.get<any>(environment.patient_url + `/patientcards/getcard?patientcardsid=${patientcardsid}&patientid=${patientid}&tenantid=${tenantid}&cardtype=${cardtype}`);
  }

  deletePatientCard(patientcardsid: any, patientId: any, tenantid: any, cardtype: any) {
    return this.http.delete<any>(environment.patient_url + `/patientcards/deletepatientcard?patientcardsid=${patientcardsid}&patientid=${patientId}&tenantid=${tenantid}&cardtype=${cardtype}`);
  }

  async saveNewImages(newImageFileData:any) {
    await this.clearImagesIfCanceled();
    this.cards.unshift(newImageFileData);
    Preferences.set({
      key: this.IMAGE_STORAGE,
      value: JSON.stringify(this.cards),
    });
  }

}

export interface UserPhoto {
  filepath: string;
  webViewpath?: string;
  type: string
}
export interface InsuranceCards {
  id: number;
  cardType: string;
  cards: {
    filepath: string;
    webViewpath: string;
    type: string;
  }[];
}
 