import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/core/services/common.service';
import { BehaviorSubject } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { AuditLog, AuditLogPg } from '../models/audit-log.interface';
@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentService {

  patientValue: any;
  templateName: any;

  constructor(private http: HttpClient, private commonService: CommonService, private loadingController: LoadingController) {
    this.templateName = this.commonService.getSessionStorage('QSuiteTemplate');
  }

  public insuranceDataSubject = new BehaviorSubject<any>(null);
  insuranceData$ = this.insuranceDataSubject.asObservable();

  getlocationList(tenantid: any) {
    let url = (environment.qappoints_url + '/self/getLocationforselfscheduling/' + tenantid);
    return this.http.get(url);
  }

  getProviderList(tenantid: any) {
    let url = (environment.qappoints_url + '/setting/getprovidersetting/' + tenantid);
    return this.http.get(url);
  }

  getAppointmentType(tenantid: any) {
    let url = `${environment.myqone_url}/qappointments/self/selfscheduling/getAppointmenttype/${tenantid}`;
    return this.http.get(url);
  }

  getTenantConfigurations(tenantid: any) {
    const url = `${environment.qappoints_url}/setting/getAllSettingList/${tenantid}`;
    return this.http.get(url);
  }

  //Masking (___) ___-____
  formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) return phoneNumber;
  
    // Remove all non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Define the mask and the desired output format
    const mask = "(999) 000-0000";
    let formattedNumber = "";
  
    let maskIndex = 0;
    let numberIndex = 0;
  
    // Iterate through the mask and replace placeholders
    while (maskIndex < mask.length && numberIndex < cleaned.length) {
      if (mask[maskIndex] === '9' || mask[maskIndex] === '0') {
        formattedNumber += cleaned[numberIndex];
        numberIndex++;
      } else {
        formattedNumber += mask[maskIndex];
      }
      maskIndex++;
    }
  
    return formattedNumber;
  }

  getTemplateStatus(tenantid: any, templatename: any) {
    let url = `${environment.pebackend_url}template/checkTemplateNames/${tenantid}/${templatename}`;
    return this.http.get(url);
  }

  getTenantNameWithId(tenantId: any) {
    let url = `${environment.auth_url}/tenant/getTenantDetailsByID/${tenantId}`;
    return this.http.get(url);
  }

  async getProviderSlots(selectedAppointmentType: any, selectedProvider: string, selectedLocation: string, selectedDate: any, time: string, tenantid: any, minvalue: any, maxvalue: any) {
    this.templateName = this.commonService.getSessionStorage('QSuiteTemplate');
    let formattedDate = selectedDate || '';
    let providerId = selectedProvider || '';
    let locationid = selectedLocation || '';
    let appointmentType = selectedAppointmentType || '';
    let AMPM = time || '';

    const url = `${environment.pebackend_url}appointment/getappointmentdetailswithmultipletemplate?providerID=${providerId}&templatename=${this.templateName}&locationid=${locationid}&selecteddate=${formattedDate}&AMPM=${AMPM}&tenantid=${tenantid}&appointmentType=${appointmentType} &minvalue=${minvalue}&maxvalue=${maxvalue}`;
    return this.http.get(url);
  }

  async getSelectedProviderSlots(tenantid: any, minvalue: any, maxvalue: any) {
    this.templateName = this.commonService.getSessionStorage('QSuiteTemplate');
    const formattedDate = this.commonService.getSessionStorage('slotDate');
    const providerId = this.commonService.getSessionStorage('providerId');
    const locationid = this.commonService.getSessionStorage('locationId');
    const appointmentType = this.commonService.getSessionStorage('appointmentType');

    const url = `${environment.pebackend_url}appointment/getappointmentdetailswithmultipletemplate?providerID=${providerId}&templatename=${this.templateName}&locationid=${locationid}&selecteddate=${formattedDate}&AMPM=&tenantid=${tenantid}&appointmentType=${appointmentType}&minvalue=${minvalue}&maxvalue=${maxvalue}`;
    return this.http.get(url);
  }

  passDatesToCalender(selectedAppointmentType: any, selectedProvider: string, selectedLocation: string, time: string, tenantid: any, min: any, max: any) {
    this.templateName = this.commonService.getSessionStorage('QSuiteTemplate');
    let providerId = selectedProvider || '';
    let locationid = selectedLocation || '';
    let appointmentType = selectedAppointmentType || '';
    let AMPM = time || '';
    const url = `${environment.pebackend_url}appointment/getcalenderdates?providerID=${providerId}&templatename=${this.templateName}&locationid=${locationid}&AMPM=${AMPM}&tenantid=${tenantid}&appointmentType=${appointmentType}&minvalue=${min}&maxvalue=${max}`;
    return this.http.get(url);
  }

  confirmSlotInfo(tenantid: any) {
    let providerID = this.commonService.getSessionStorage('providerId');
    let templateName = this.commonService.getSessionStorage('QSuiteTemplate');
    let selecteddate = this.commonService.getSessionStorage('formattedSlotDate');
    let dtStartTime = this.commonService.getSessionStorage('formattedSlotTime');
    let dtEndTime = this.commonService.getSessionStorage('formattedEndSlotTime');
    const url = `${environment.pebackend_url}appointment/getappointmentslotstatusformultipletemplate?providerID=${providerID}&templatename=${templateName}&selecteddate=${selecteddate}&dtStartTime=${dtStartTime}&tenantid=${tenantid}&dtEndTime=${dtEndTime}`;
    return this.http.get(url);
  }

  createNewUserForGuest(data: any) {
    let url = `${environment.myqone_url}/auth/users/createPatientUser`;
    return this.http.post(url, data);
  }

  savePatientUser(data: any) {
    let url = `${environment.myqone_url}/auth/users/saveUsersPatient`;
    return this.http.post(url, data);
  }

  bookAppointment(additionalNotes: any, tenantid: any) {
    let nTemplateAllocationMasterID = this.commonService.getSessionStorage('nTemplateAllocationMasterID');
    let nTemplateAllocationID = this.commonService.getSessionStorage('nTemplateAllocationID');
    let npatientid = this.commonService.getSessionStorage('patientId');
    const parts = npatientid.split('-');
    const trimmedValue = parts[0];
    const url = `${environment.pebackend_url}appointment/bookappointment/${nTemplateAllocationMasterID}/${nTemplateAllocationID}/${trimmedValue}/${tenantid}?notes=${encodeURIComponent(additionalNotes)}`;
    return this.http.get(url);
  }

  bookAptForPg(tenantid: any) {
    let nMSTAppointmentID = this.commonService.getSessionStorage('nMSTAppointmentID');
    let nDTLAppointmentID = this.commonService.getSessionStorage('nDTLAppointmentID');
    let npatientid = this.commonService.getSessionStorage('patientId');
    const parts = npatientid.split('-');
    const trimmedValue = parts[0];
    const url = `${environment.qappoints_url}/self/getAppointmentDetails/${nMSTAppointmentID}/${nDTLAppointmentID}/${trimmedValue}/${tenantid}`;
    return this.http.post(url, {});
  }

  checkMyQoneUserByPatientDemographics(email: any) {
    let url = `${environment.myqone_url}/auth/users/userexists?username=${email}`;
    return this.http.get(url);
  }

  getPatientDemographicsbyPid(patientid: any) {
    let url = `${environment.patient_url}/patient/getpatientbyid/${patientid}`;
    return this.http.get(url);
  }

  getpatientinsuranceDetails(PatientId: any, tenantid: any) {
    let url = `${environment.patient_url}/patient/getinsurancesbypatientid/${tenantid}/${PatientId}`;
    return this.http.get(url);
  }

  getInsuranceCompany(tenantId: any, insurancecompanyid: any) {
    return this.http.get(environment.myqone_url + `/insurance/insuranceplan/getInsurancePlanList/${tenantId}/${insurancecompanyid}`)
  }

  relationList() {
    return this.http.get(environment.myqone_url + "/insurance/patientrelationship/getAll");
  }

  getAllCompanyList(tenantId: any) {
    return this.http.get(environment.myqone_url + `/insurance/insurancecompany/getInsuranceCompanyListByTenantid/${tenantId}`)
  }

  getInsuranceCardList(patientid: any, tenantid: any) {
    let url = (`${environment.patient_url}/patientcards/getpatientcards/${patientid}/${tenantid}/INSURANCE`);
    return this.http.get(url);
  }

  getPatientCardList(patientid: any, tenantid: any, cardtype: any) {
    let url = (`${environment.patient_url}/patientcards/getpatientcards/${patientid}/${tenantid}/${cardtype}`)
    return this.http.get(url);
  }

  getInsuranceCompanyByPlanID(insuranceplanid: any, tenantid: any) {
    let url = (`${environment.myqone_url}/insurance/insuranceplan/getInsuranceCompany/${insuranceplanid}/${tenantid}`);
    return this.http.get(url);
  }

  getPatientCardByte(patientcardsid: any, patientid: any, tenantid: any, cardtype: any) {
    return this.http.get<any>(environment.patient_url + `/patientcards/getcard?patientcardsid=${patientcardsid}&patientid=${patientid}&tenantid=${tenantid}&cardtype=${cardtype}`);
  }

  checkExistingPatient(data: any) {
    let url = `${environment.patient_url}/pe2/intake/patientByDemographics`;
    return this.http.post(url, data);
  }

  QuickSavePatient(demographicsData: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        "savepatienttosql": demographicsData.savepatienttosql ? demographicsData.savepatienttosql : ''
      }),
    };
    return this.http.post<{ patientid: string, message: string, patientcode: string }>(
      environment.myqone_url + "/patient/patient/save",
      demographicsData,
      httpOptions
    );
  }

  quickSavePatientPortal(patientid: any, email: any, tenantid: any) {
    let url = `${environment.pebackend_url}patientportal/quickactivateportalaccount?npatientid=${patientid}&sEmail=${email}&sendemail=true&tenantid=${tenantid}`;
    return this.http.post(url, {});
  }

  generatePatientCode(tenantid: any) {
    let url = `${environment.patient_url}/patient/getsqlpatietcode?tenantid=${tenantid}`;
    return this.http.get(url);
  }

  checkDuplicatePatientCode(patientCode: any, tenantid: any) {
    let url = `${environment.patient_url}/patient/checkduplicatesqlpatientcode?patientcode=${patientCode}&tenantid=${tenantid}`;
    return this.http.get(url);
  }

  updatePatientDemographics(data: any) {
    let url = `${environment.patient_url}/pe2/updateDemographics`;
    return this.http.put(url, data);
  }

  saveInsurance(data: any) {
    let url = `${environment.patient_url}/pe2/saveInsurance`;
    return this.http.post(url, data);
  }

  appendSelfPayToPatient(tenantid: string, npatientid: any) {
    let url = `${environment.pebackend_url}selfpay/saveSelf?tenantid=${tenantid}`;
    return this.http.post(url, { npatientid: npatientid });
  }

  updateInsurance(data: any) {
    let url = `${environment.patient_url}/pe2/updateInsurance`;
    return this.http.put(url, data);
  }

  saveCardDetails(frontImage: File, patientid: any, tenantid: any, cardtype: any, backImage: File) {
    let url = (environment.patient_url + '/patientcards/save');
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('frontimage', frontImage);
    formData.append('patientid', patientid);
    formData.append('tenantid', tenantid);
    formData.append('cardtype', cardtype);
    formData.append('backimage', backImage);
    return this.http.post<any>(url, formData, { headers });
  }

  verifyToken(token: string) {
    let verifyUrl = `${environment.pebackend_url}validatecaptcha?response=${token}`;
    return this.http.post(verifyUrl, { response: token });
  }

  sendMail(mailData: { toperson: string, cc: string, subject: string, body: string }) {
    let url = `${environment.auth_url}/sendMailPostCall`;
    return this.http.post(url, mailData);
  }

  //portal email is exist or not api -demographic
  getCheckEmailIsExist(tenantid: any, email: any) {
    let url = `${environment.pebackend_url}patientportal/checkportalaccountforqintake?tenantid=${tenantid}&sLoginName=${email}`;
    return this.http.get(url);
  }

  //create portal account -signup
  getCreatePortalAccount(patientid: any, email: any, password: string, tenantid: any) {
    let url = `${environment.pebackend_url}patientportal/createportalaccountforqintake?npatientid=${patientid}&sEmail=${email}&sPortalPassword=${password}&tenantid=${tenantid}`;
    return this.http.get(url);
  }

  getReasonsByTenentId(tenantid: string) {
    const url = `${environment.myqone_url}/qappointments/reason/getAllReasonList/${tenantid}`;
    return this.http.get(url);
  }

  updateCardDetails(frontImage: File, patientid: any, tenantid: any, cardtype: any, backImage: File, patientcardsid: any) {
    let url = (environment.patient_url + '/patientcards/update');
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('frontimage', frontImage);
    formData.append('patientid', patientid);
    formData.append('tenantid', tenantid);
    formData.append('cardtype', cardtype);
    formData.append('backimage', backImage);
    formData.append('patientcardsid', patientcardsid);
    return this.http.put<any>(url, formData, { headers });
  }

  saveAuditLogsToSql(auditData: AuditLog) {
    let url = `${environment.pebackend_url}sqlappointmentauditlog`;
    return this.http.post(url, auditData);
  }

  saveAuditLogsToPg(auditData: AuditLogPg) {
    let url = `${environment.auditUrl}WriteAuditLog`;
    return this.http.post(url, auditData);
  }

  getAuditReports(startDate: any, endDate: any, pageNum: any, pageSize: any, search: any) {
    let npatientid = this.commonService.getSessionStorage('patientId');
    const url = `${environment.qappoints_url}/self/selfschedulingReport?todate=${endDate}&fromdate=${startDate}&search=${search}&patientid=${npatientid}&pageno=${pageNum}&pagesize=${pageSize}`;
    return this.http.get(url);
  }

  getBillingStatus(tenantid: any, patientid: any) {
    return this.http.get(environment.qintake_url + `/queue/insurance/billing/${tenantid}/${patientid}`);
  }

}
