import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionTimeOutPopupComponent } from '../components/session-time-out-popup/session-time-out-popup.component';
import { CommonService } from 'src/app/core/services/common.service';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { ToasterService } from 'src/app/services/toaster.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {
  private bsModalRef: BsModalRef;
  private refreshedTokenSubject = new BehaviorSubject<string | null>(null); // Holds the latest token
  tenant: any;
  PI: any;

  constructor(private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    public toasterService: ToasterService) {
    this.tenant = this.commonService.getSessionStorage('tenant');
    this.PI = this.commonService.getSessionStorage('PI');
  }

  showSessionTimeoutModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bsModalRef = this.modalService.show(SessionTimeOutPopupComponent);
      this.bsModalRef.onHide.subscribe(async () => {
        const result = this.bsModalRef.content;
        if (result === true) {
          try {
            await this.setNewUser();
            resolve();
          } catch (error) {
            this.toasterService.showError('Error renewing token: please contact admin');
            reject(error);
          }
        } else {
          this.router.navigate(['/patient-appointment', this.tenant]);
          reject('Session expired, user redirected.');
        }
      });
    });
  }

  async setNewUser() {
    const auth = getAuth();
    const currentUser = auth.currentUser; // Check if a current user session exists
  
    if (currentUser) {
      try {
        // Refresh the token for the current user
        const accessToken = await currentUser.getIdToken(true); // `true` forces a refresh
        this.refreshedTokenSubject.next(accessToken); // Emit the refreshed token
        
        const obj = { userid: currentUser.uid, token: accessToken };
        this.commonService.setSessionStorage('UID', JSON.stringify(obj.userid));
        this.commonService.setSessionStorage('PI', JSON.stringify(obj));
        console.warn('Token refreshed successfully for existing anonymous user');
        
        return; // Exit the function since token refresh succeeded
      } catch (error) {
        console.error('Error refreshing token for existing user, trying new sign-in:', error);
      }
    }
  
    // If there's no current user or the refresh failed, proceed to sign in anonymously
    await signInAnonymously(auth).then(async (userCredential) => {
      const user = userCredential.user;
      const accessToken = await user.getIdToken();
  
      this.refreshedTokenSubject.next(accessToken); // Emit the new token
      const obj = { userid: user.uid, token: accessToken };
      this.commonService.setSessionStorage('UID', JSON.stringify(obj.userid));
      this.commonService.setSessionStorage('PI', JSON.stringify(obj));
      console.warn('Token refreshed with a new anonymous user');
    }).catch((error) => {
      console.error('Error signing in anonymously:', error);
      this.toasterService.showError('Error renewing token: please contact admin');
    });
  }

  getTokenObservable() {
    return this.refreshedTokenSubject.asObservable();
  }
}