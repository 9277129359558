import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 768px)');

  constructor(private deviceService: DeviceDetectorService) {
    this.isDesktop = !!this.breakpoint.matches;
    this.epicFunction();
  }

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  osType!: string;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;
  isIOS!: boolean ;
  isAndroid!: boolean ;
  epicFunction(){
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.osType = this.deviceInfo.os;
  }
}
