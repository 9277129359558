import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { catchError, EMPTY } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AuditLogService } from '../../services/audit-log.service';
import { PatientAppointmentLoginService } from '../../services/patient-appointment-login.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
@Component({
  selector: 'app-self-scheduling-logout',
  templateUrl: './self-scheduling-logout.component.html',
  styleUrls: ['./self-scheduling-logout.component.scss'],
})
export class SelfSchedulingLogoutComponent  implements OnInit {

  tenantId:any;
  patientId:any;

  constructor(private commonService: CommonService, private router: Router, private popOverCtrl: PopoverController, private patientLoginService: PatientAppointmentLoginService, private auditLogService: AuditLogService, private appointmentService: PatientAppointmentService) { }

  async ngOnInit() {
    this.tenantId = await this.commonService.getSessionStorage('tenant');
    this.patientId = await this.commonService.getSessionStorage('patientId');
  }

  async logotUser() {
    await this.popOverCtrl.dismiss();
    await this.saveAuditLogsToPg('patientLogout');
    this.patientLoginService.logOut(this.tenantId);
  }

  async saveAuditLogsToPg(value: string) {
    let tenantId = this.commonService.getSessionStorage('tenant');
    let name = this.commonService.getSessionStorage('fn');
    const parts = this.patientId.split('-');
    const trimmedValue = parts[0];

    const auditData = this.auditLogService.generateAuditLogForPg(value, name, tenantId, trimmedValue);
    if (auditData) {
      this.appointmentService.saveAuditLogsToPg(auditData)
        .pipe(
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        ).subscribe(); // No action needed for the response
    }
  }

  async auditReport() {
    await this.popOverCtrl.dismiss();
    this.router.navigate(['./app/self-audit-report']);
  }

}
