import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-session-time-out-popup',
  templateUrl: './session-time-out-popup.component.html',
  styleUrls: ['./session-time-out-popup.component.scss'],
})
export class SessionTimeOutPopupComponent  implements OnInit {

  constructor(public modalController: ModalController, private bsModalRef: BsModalRef) {}

  ngOnInit() {}

  onClose() {
    this.bsModalRef.hide();
    this.bsModalRef.content = false;
  }

  onDone(){
    this.bsModalRef.hide();
    this.bsModalRef.content = true;
  }
}
