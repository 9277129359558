import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { PatientAppointmentService } from '../services/patient-appointment.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { CommonService } from 'src/app/core/services/common.service';
import { IonicPhotoServiceService } from '../services/ionic-photo-service.service';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigurationResolver {
  constructor(
    private appointmentService: PatientAppointmentService,
    private router: Router,
    private toasterService: ToasterService,
    private commonService: CommonService,
    private photoService: IonicPhotoServiceService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const loading = await this.photoService.showLoader('Please wait...');
    try {
      await this.setNewUser();
  
      const tenantId = route.paramMap.get('tenant');
      if (!tenantId) {
        throw new Error('Tenant ID is missing');
      }
  
      const res: any = await firstValueFrom(this.appointmentService.getTenantConfigurations(tenantId));
      if (res?.status === true) {
        return res; // Return the API response if successful
      } else {
        throw new Error('Invalid tenant configuration');
      }
    } catch (error) {
      console.error('Error during resolve:', error);
      this.toasterService.showError('Unexpected Error, Please Contact Your Admin');
      this.router.navigate(['/app']);
      return null;
    } finally {
      loading.dismiss();
    }
  }

  async setNewUser(): Promise<void> {
    const auth = getAuth();
    try {
      const userCredential = await signInAnonymously(auth);
      let user = userCredential.user;
      const accessToken = user["accessToken"];
      let obj = {
        userid: user.uid,
        token: accessToken
      };
      this.commonService.setSessionStorage('PI', JSON.stringify(obj));
      this.commonService.setSessionStorage('UID', JSON.stringify(obj.userid));
    } catch (error) {
      console.error(error);
      this.toasterService.showError('Error signing in anonymously');
      throw error; // Rethrow the error to handle it in the resolver
    }
  }

}