import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from 'src/app/core/services/common.service';
import { TokenExpirationService } from '../../services/token-expiration.service';

@Component({
  selector: 'app-self-scheduling-cropper',
  templateUrl: './self-scheduling-cropper.component.html',
  styleUrls: ['./self-scheduling-cropper.component.scss'],
})
export class SelfSchedulingCropperComponent  implements OnInit, OnDestroy {

  data: any;
  croppedImage!: string;
  imageChangedEvent: any = '';
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;
  patientType: string;

  constructor(public bsModalRef: BsModalRef, @Optional() private dialogRef: DialogRef, private router: Router,
  private deviceService: DeviceDetectorService, private commonService: CommonService, private tokenExpirationService: TokenExpirationService,) {
    this.epicFunction();
    const navigation = this.router.getCurrentNavigation();
    this.data = navigation?.extras.state?.['data'];
    this.patientType = this.commonService.getSessionStorage("patientType");
  }

  ngOnInit() {}

  ngOnDestroy() {}

  //Device Dectector
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
 
  imageCropped(event: ImageCroppedEvent) {
    if (event && event.objectUrl) {
      this.croppedImage = event.objectUrl;
      return;
    } else {
      console.error('Error cropping the image.');
    }
  }
 
  imageLoaded(image: LoadedImage) {
    // console.log(image);
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
 
  confirmAction() {
    const cardType = this.commonService.getSessionStorage('cardType');
    const dataToPass = { confirmed: true, croppedImage: this.croppedImage };
    if (this.isMobile) {
      this.router.navigate(['/app/patient-insurance-cards-edit-add', cardType], { state: dataToPass });
    } else {
      this.dialogRef.close(dataToPass);
    }
  }  
  cancelAction() {
    const cardType = this.commonService.getSessionStorage('cardType');
    const dataToPass = { confirmed: false };
    if (this.isMobile) {
      this.router.navigate(['/app/patient-insurance-cards-edit-add', cardType], { state: dataToPass });
    } else {
      this.dialogRef.close(dataToPass);
    }
  }

}
