import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, from, Observable, Subject, switchMap, throwError } from 'rxjs';
import { CommonService } from '../services/common.service';
import { PatientAppointmentLoginService } from 'src/app/main/self-scheduling/services/patient-appointment-login.service';
import { TokenExpirationService } from 'src/app/main/self-scheduling/services/token-expiration.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing = false; // Flag for token refresh status
  private refreshTokenSubject: Subject<boolean> = new Subject<boolean>(); // Tracks refresh status

  constructor(private commonService: CommonService, private loginService: PatientAppointmentLoginService, private tokenExpirationService: TokenExpirationService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const patientInfo: any = this.commonService.getSessionStorage('PI');

    if (patientInfo) {
      return from(this.loginService.isTokenExpired(patientInfo.token)).pipe(
        switchMap((isExpired) => {
          if (isExpired) {
            // console.warn('Token expired. Checking for refresh...');

            // If a refresh is already in progress, wait for it to complete
            if (this.isRefreshing) {
              return this.refreshTokenSubject.pipe(
                switchMap(() => this.retryRequestWithNewToken(request, next))
              );
            } else {
              // Start refreshing the token
              this.isRefreshing = true;
              return from(this.tokenExpirationService.showSessionTimeoutModal()).pipe(
                switchMap(() => {
                  // console.warn('passing token to request');
                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(true); // Notify waiting requests
                  this.refreshTokenSubject.complete(); // Complete the subject to prevent memory leaks
                  this.refreshTokenSubject = new Subject<boolean>(); // Reset for future use
                  return this.retryRequestWithNewToken(request, next);
                }),
                catchError((error) => {
                  console.error('Error refreshing token:', error);
                  this.isRefreshing = false;
                  this.refreshTokenSubject.error(error); // Propagate error to waiting requests
                  return throwError(() => error);
                })
              );
            }
          } else {
            // If the token is valid, proceed with the original request
            return this.retryRequestWithExistingToken(request, next);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error in interceptor:', error);
          return throwError(() => error);
        })
      );
    }

    // If no patient info, proceed with the original request
    return next.handle(request);
  }

  private retryRequestWithExistingToken(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // console.warn('Attempting request with existing token');
    const newPatientInfo: any = this.commonService.getSessionStorage('PI');
    const refreshedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${newPatientInfo?.token || ''}`, // Use the existing token from session
      },
    });

    return next.handle(refreshedRequest);
  }


  private retryRequestWithNewToken(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // console.warn('Retrying request with a newly refreshed token');

    // Return an observable from the subscription to handle async token fetching
    return this.tokenExpirationService.getTokenObservable().pipe(
      switchMap(refreshedToken => {
        // console.warn(refreshedToken);

        const refreshedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${refreshedToken}`, // Retrieve updated token
          },
        });

        // Proceed with the request and return the observable to continue the chain
        return next.handle(refreshedRequest);
      })
    );
  }

}