<div class="forgot_password_main">
  <div class="forgot_password_title">
    <div class="column ion-text-center">
      <ion-text color="gray80" class="bodyL-txt-large bodyM-txt-medium-mobile pb-3 main-line line-28">
        <span class="title">{{tenantName}}</span>
      </ion-text>
      <!-- <ion-text color="gray80" class="tagline pt-3">Our tagline is the best tagline for you</ion-text> -->
    </div>
  </div>
  <!-- <div class="forgot_password_form">
    <form class="k-form" [formGroup]="forgotPasswordForm">
      <div class="column mb-20" *ngIf="!showMsg">
        <ion-text class="bodyL-txt-bold mb-5" color="black">Forgot your password?</ion-text>
        <ion-text class="bodyM-txt" color="gray80">Enter your email and we’ll send you a link to reset it</ion-text>
      </div>
      <div class="column mb-20" *ngIf="showMsg">
        <ion-text class="bodyL-txt-bold mb-5" color="black">Email sent to {{email}}</ion-text>
        <ion-text class="bodyM-txt" color="gray80">Check your inbox for a link to reset your password</ion-text>
      </div>
      <div class="mb-30" *ngIf="!showMsg">
        <kendo-formfield style="margin-top: 0;">
          <kendo-textbox class="bodyM-txt" color="gray100"  placeholder="Email address" formControlName="email" #email required></kendo-textbox>
          <kendo-formerror>Please enter a valid email address</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="text-center mb-40 mt-15">
        <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn normal" (click)="sendPasswordLink()"
         [disabled]="!this.forgotPasswordForm.valid && disableResetLink" *ngIf="!showMsg">
          <ion-text>Send reset link</ion-text>
        </ion-button>
        <ion-button fill="solid" color="ion-color-white" class="filled-clear-btn normal bold-font-weight" (click)="resendPasswordLink()" *ngIf="showMsg">
          <ion-text>Re-send email</ion-text>
        </ion-button>
      </div>
    </form>
  </div> -->
  <div class="forgot_password_form">
    <form class="k-form" [formGroup]="forgotPasswordForm">
      <!-- Initial message asking to enter email -->
      <div class="column mb-20" *ngIf="!showMsg">
        <ion-text class="bodyL-txt-bold mb-5" color="black">Forgot your password?</ion-text>
        <ion-text class="bodyM-txt" color="gray80">Enter your email and we’ll send you a link to reset it</ion-text>
      </div>
  
      <!-- Message indicating email has been sent -->
      <div class="column mb-20" *ngIf="showMsg">
        <ion-text class="bodyL-txt-bold mb-5" color="black">Email sent to {{ email }}</ion-text>
        <ion-text class="bodyM-txt" color="gray80">Check your inbox for a link to reset your password</ion-text>
      </div>
  
      <!-- Email input field -->
      <div class="mb-30" *ngIf="!showMsg">
        <kendo-formfield style="margin-top: 0;">
          <kendo-textbox class="bodyL-txt-medium" color="gray100" placeholder="Email address" formControlName="email" #email required></kendo-textbox>
          <kendo-formerror>Please enter a valid email address</kendo-formerror>
        </kendo-formfield>
      </div>
  
      <!-- Buttons: Send reset link / Re-send email -->
      <div class="text-center mb-40 mt-15">
        <!-- Send reset link button - Disabled when the form is invalid or isProcessing is true -->
        <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn normal" (click)="sendPasswordLink()"
          [disabled]="!forgotPasswordForm.valid || disableResetLink || isProcessing" *ngIf="!showMsg">
          <ion-text>Send reset link</ion-text>
        </ion-button>
  
        <!-- Re-send email button -->
        <ion-button fill="solid" color="ion-color-white" class="filled-clear-btn normal bold-font-weight" 
          (click)="resendPasswordLink()" [disabled]="isProcessing" *ngIf="showMsg">
          <ion-text>Re-send email</ion-text>
        </ion-button>
      </div>
    </form>
  </div>  
</div>


  