<div class="kendopopup preview_img_cropper">
  <!-- <kendo-dialog class="preview_img_cropper_dialog"> -->
    
    <kendo-dialog-titlebar class="text-center" style="border-bottom: 1px solid #CCD4D8">
      <div class="ion-align-items-center w-100">
        <ion-text class="h3-M tabletFont" color="gray100" *ngIf="!isMobile">Preview Image</ion-text>
        <ion-text class="h3-M text-blue-color bodyL-txt-large-mobile tabletFont" *ngIf="isMobile">Preview Image</ion-text>
      </div>
    </kendo-dialog-titlebar>
    
    <div class="preview_img_cropper_content overflowY p-10" [ngClass]="isMobile == true ? 'mobile_cropper_height' : 'md-popup-content'">
      <div class="croppModal_body">
        <div>
          <image-cropper class="custom-content"
          [imageURL]="data"
          [maintainAspectRatio]="true"
          [aspectRatio]="300 / 186"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
        <div class="image-content">
          <div class="text-center mb-10 mt-10">
            <ion-text color="gray100" class="bodyM-txt-bold uppercase">Cropped Image</ion-text>
          </div>
          <div class="text-center">
            <img [src]="croppedImage">
          </div>
        </div>
      </div>
    </div>
 
    <kendo-dialog-actions class="borderTopGray20 fixedBottom" *ngIf="!isMobile">
      <div class="row w-100 ion-justify-content-center align-items-center">
        <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20">
          <ion-text (click)="cancelAction()">Cancel</ion-text>
        </ion-button>
        <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
          <ion-text (click)="confirmAction()">Confirm</ion-text>
        </ion-button>
      </div>
    </kendo-dialog-actions>
  <!-- </kendo-dialog> -->
 
  <ion-footer class="ion-bg-unfocused-color page-bottom" *ngIf="isMobile">
    <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"  (click)="cancelAction()">
        <ion-text>Cancel</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn" (click)="confirmAction()">
        <ion-text >Confirm</ion-text>
      </ion-button>
    </div>
  </ion-footer>


 
</div>