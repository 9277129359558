import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentLoginService {

  constructor(private commonService: CommonService, private http: HttpClient, private router: Router) { }

  doLogin(value: { email: string; password: string; }) {
    console.log(value.email, value.password);

    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
            // let user = firebase.auth().currentUser;
            // if (user?.emailVerified) {
            //   resolve(res);
            // }
            // else {
            //   window.alert('Email not verified')
            //   firebase.auth().signOut();
            // }
          },
          err => reject(err)
        );
    });
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      let user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  //authenticate portal user(login)
  getPortalAccessUserDetails(username: string, password: string, tenantId: any) {
    const url = `${environment.pebackend_url}patientportal/authenticatepatientportal?username=${username}&password=${password}&tenantid=${tenantId}`;
    return this.http.get<any>(url);
  }

  //token generation api for portal
  getTokenforPortalUser(emrmappingid: string) {
    const url = `${environment.auth_url}/users/authenticateUser/${emrmappingid}`;
    return this.http.get<any>(url);
  }

  async isTokenExpired(token: string): Promise<boolean> {
    if (!token) return true;
  
    try {
      // Decode the Firebase token
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expiry = payload.exp * 1000; // Convert expiry to milliseconds (UTC)
  
      // Get the current time in UTC
      const now = Date.now();
      // console.warn('Expiry Time (UTC):', new Date(expiry).toLocaleString());
  
      return now > expiry;
    } catch (e) {
      // If there's an error decoding the token, consider it expired
      console.error('Error decoding token:', e);
      return true;
    }
  }

  ifEmailExistToResetPassword(email: any, tenantId:any) {
    let url = `${environment.pebackend_url}patientportal/patientportalaccess?sLoginName=${email}&tenantid=${tenantId}`;
    return this.http.get<any>(url);
  }

  sendMailIfResetPassword(nPatientID: any, tenantId: any) {
    let url = `${environment.pebackend_url}sendemailtouser?npatientid=${nPatientID}&tenantid=${tenantId}`;
    return this.http.post<any>(url, {});
  }

  logOut(tenantId:any) {
    this.commonService.removeAllStorage();
    this.router.navigate(['/patient-appointment', tenantId]);
  }

}
