<div class="self-main_container">
  <div class="ion-header-bg-color mid-container header">
    
    <div class="w-100 justify-content-sb d-flex heading-block px-30 mid-header-section container align-items-center">
      <div class="50 d-flex top-div mobile100">
        <div class="column mobile100 mobile-center">
          <ion-text color="gray80" class="bodyL-txt-large bodyM-txt-medium-mobile pb-3 ion-text-nowrap main-line">
            {{tenantName}}
          </ion-text>
          <div class="" *ngIf="!isDesktop">
            <ion-text class="bodyS-txt ion-color-gray80 subtxt-number" *ngIf="!isHelpine">'(___) ___-____'</ion-text>
            <a class="bodyS-txt ion-color-gray80 subtxt-number" style="text-decoration: none;"
              [href]="'tel:' + helpLineNumber" *ngIf="isHelpine">{{ helpLineNumber }}</a>
          </div>
        </div>
      </div>

      <div class="row txt-end w-100" *ngIf="isDesktop">
        <div class="column pl-10 header-subtxt" [ngClass]="isExistingUser == true ? 'mr-20' : 'mr-0'">
          <ion-text class="bodyS-txt text-center subtxt" color="gray80">Need Help?</ion-text>
          <ion-text class="number-txt ion-color-gray80 subtxt-number" *ngIf="!isHelpine">'(___) ___-____'</ion-text>
          <a class="number-txt ion-color-gray80 subtxt-number font18" style="text-decoration: none;"
            [href]="'tel:' + helpLineNumber" *ngIf="isHelpine">{{ helpLineNumber }}</a>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end widthAuto"
        *ngIf="isExistingUser">
        <ion-avatar class="default-avatar cursor" slot="start" (click)="popOver($event)">
          <span>{{patientEmail | initials }}</span>
        </ion-avatar>
      </div>

    </div>
  </div>
</div>