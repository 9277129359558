<div class="timeout_warning_dialog">
  <kendo-dialog class="k-backdrop-blur-lg">
      <div class="timeout_warning_main text-center">
          <div class="mb-10">
              <ion-text class="bodyL-txt-medium" color="gray100">Your session is about to expire.</ion-text>
          </div>
          <div class="mb-40">
              <ion-text class="bodyM-txt" color="gray80">Do you want to extend it?</ion-text>
          </div>
          <!-- <div class="mb-20">
              <ion-text class="timefont" color="gray60">1<span class="light-font-weight"> min</span> 39 <span
                      class="light-font-weight"> sec(s)</span></ion-text>
          </div>
          <div class="mb-20">
              <ion-progress-bar color="primary" value="0.5"></ion-progress-bar>
          </div> -->
          <div class="d-flex ion-align-items-center ion-justify-content-between timeoutlink">
              <ion-button fill="outline" color="highlight" class="capitalize filled-outline-btn w-100 mr-10"
                   (click)="onDone()"> <ion-text>OK</ion-text>
              </ion-button>
              <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100"
                  (click)="onClose()" (click)="onClose()">
                  <ion-text>Cancel</ion-text>
              </ion-button>
          </div>
      </div>
  </kendo-dialog>
</div>